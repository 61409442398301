<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import LandingPage from './components/LandingPage.vue'
import NotFoundPage from './components/NotFoundPage.vue'
import axios from 'axios'
import { reactive } from 'vue'
import LandingPageNW from './components/LandingPageNW.vue'

const state = reactive({
  pageReady: false,
  pageError: false,
  firstName: "",
  videoLink: "",
  videoId: "",
  pdfUrl: "",
})

const gicKey = location.pathname.startsWith('/insurance/') ? location.pathname.substr(11) : location.pathname.substr(1)

axios.get(`https://gateway.gonow.cx/gicvideo/${gicKey}`)
  .then((res) => {
    if (res.status !== 200) {
      throw 'Error'
    }
    if (res.data["error"] !== undefined) {
      throw res.data["error"]
    }

    state.firstName = res.data["Name"]
    state.videoId = gicKey
    state.videoLink = res.data["VideoLink"]
    if (state.videoLink == null) {
      state.videoLink = `https://vid.pirsonal.com/embed/${state.videoId}`
    }
    state.pdfUrl = res.data["pdfURL"]

    document.title = `GIC Insurance Statement for ${state.firstName}`
    state.pageReady = true
  })
  .catch(() => {
    document.title = `Page not found`
    state.pageError = true
  })
</script>

<template>
  <LandingPageNW
    v-if="state.pageReady && state.videoLink.startsWith('https://landing.nxwv.io')"
    :video-id="state.videoId"
    :video-link="state.videoLink"
    :first-name="state.firstName"
    :pdf-url="state.pdfUrl"
  />
  <LandingPage
    v-else-if="state.pageReady"
    :video-id="state.videoId"
    :video-link="state.videoLink"
    :first-name="state.firstName"
    :pdf-url="state.pdfUrl"
  />
  <NotFoundPage v-else-if="state.pageError" />
  <p v-else>Loading...</p>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
</style>
