<script setup>
import { onMounted, defineProps } from "@vue/runtime-core";

const prop = defineProps({ firstName: String, videoLink: String, pdfUrl: String });

function loadVideo() {
  const regex = /https:\/\/landing\.nxwv\.io\/(.+)\/(.+)/g
  const script = document.createElement('script')
  const match = regex.exec(prop.videoLink)
  if (match !== null) {
    let expId = match[1];
    script.setAttribute('data-default-nexweave-experience', expId);
    script.setAttribute('service-id', '3')

    script.onload = function () {
      // eslint-disable-next-line no-undef
      nexweave.setValues({})
    };
    document.head.appendChild(script)
    script.src = 'https://script.nxwv.io/embed-v1.0.1.js'
  }
}

onMounted(() => {
  loadVideo()
})
</script>
<template>
  <div class="container mx-auto space-y-6 p-2 mb-12">
    <h1
      class="text-3xl font-semibold"
    >Hi {{ firstName }}, this is a summary of your insurance premiums!</h1>
    <p>More details is in the form of a video below. Enjoy watching!</p>
    <div
      id="vidcontainer"
      class="aspect-w-16 aspect-h-9 border-solid border-green-700 border-2 bg-gray-50"
    >
      <div id="nexweave"></div>
    </div>
    <div class="p-2 flex-grow border-solid border-green-500 border-4 w-3xl">
      <p class="p-2 text-xl">To stay protected, please make a payment soon.</p>
      <button class="px-4 py-2 text-2xl bg-green-500 hover:bg-green-700 text-white rounded">Pay Now</button>
    </div>
    <p class="py-3">
      You can also download this statement in
      <a
        :href="pdfUrl"
        target="_blank"
        class="text-green-700 focus:text-blue-900 hover:bg-green-700 hover:text-white"
      >PDF</a>.
    </p>
  </div>
</template>

<style scoped>
a {
  color: #42b983;
}

label {
  margin: 0 0.5em;
  font-weight: bold;
}

code {
  background-color: #eee;
  padding: 2px 4px;
  border-radius: 4px;
  color: #304455;
}
</style>
