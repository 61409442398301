<script setup>
</script>

<template>
  <div class="container mx-auto space-y-2 p-2 mb-12">
    <h1 class="text-3xl font-semibold">Oops!</h1>
    <p>Sorry, it seems the link you requested already expired.</p>
    <p>Kindly request for new statement link. Thank you</p>
  </div>
</template>

<style scoped>
a {
  color: #42b983;
}

label {
  margin: 0 0.5em;
  font-weight: bold;
}

code {
  background-color: #eee;
  padding: 2px 4px;
  border-radius: 4px;
  color: #304455;
}
</style>
